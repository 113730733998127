<!-- 首页 -->
<template>
  <div class="main-container">
    <div class="main-wrap">
      <p>{{title}} --- component: views/Home/Main</p>
      <br />
      <br />
      <p>@/utils/tools中handleTicket方法下，已将入口Info页面修改为Home页面跳转</p>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted } from "vue";
export default {
  name: "Main",
  setup() {
    const data = reactive({
      title: "This is Main Page",
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style scoped lang="scss">
.main-container {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  box-sizing: border-box;
  p {
    font-family: 'SourceLight'; 
    font-size: 30px;
    color: #3d8fff;
    text-align: center;
  }
}
</style>
